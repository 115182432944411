//Дефолтные стили

.app-background-gradient {
	background: -webkit-radial-gradient(0 100%,ellipse cover,rgba(104,128,138,.4) 10%,rgba(138,114,76,0) 40%),linear-gradient(180deg,rgba(57,173,219,.25) 0,rgba(42,60,87,.4)),linear-gradient(135deg,#000000,#092756)
}

// Стили для Atol 

@atol-main-color: #d00014;

.atol-background-gradient {
	background:rgb(36,36,36);
	background: linear-gradient(323deg, rgb(20,32,33) 0%, rgba(39,59,60,1) 24%, rgba(32,55,56,1) 54%, rgb(16,31,31) 100%) !important;
}

//Sider

.menu-sider-atol.ant-menu-dark .ant-menu-item, .ant-menu-dark .ant-menu-item-group-title, .ant-menu-dark .ant-menu-item > a, .ant-menu-dark .ant-menu-item > span > a {
	color: rgba(255, 255, 255, 0.8);
}
.menu-sider-atol.ant-menu.ant-menu-dark, .ant-menu-dark .ant-menu-sub, .ant-menu.ant-menu-dark .ant-menu-sub {
	color: rgba(255, 255, 255, 0.8);
}
.menu-sider-atol.ant-menu-dark .ant-menu-item:hover, .ant-menu-dark .ant-menu-item-active, .ant-menu-dark .ant-menu-submenu-active, .ant-menu-dark .ant-menu-submenu-open, .ant-menu-dark .ant-menu-submenu-selected, .ant-menu-dark .ant-menu-submenu-title:hover{
	color: #fff;
}
.menu-head-atol {
	height: 64px;
	background-color: #ffffff;
    box-shadow: unset;
}

//Button
.btn-atol {
	border-color: @atol-main-color;
    background: @atol-main-color;
	opacity: 0.9;
}
.btn-atol:visited {
	border-color: @atol-main-color;
    background: @atol-main-color;
	opacity: 1;
}
.btn-atol:focus, .btn-atol:hover {
	border-color: @atol-main-color;
    background: @atol-main-color;
	opacity: 0.7;
}

//Loading logo
.loading-logo-atol {
	position: absolute;
	width: 50%;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
.loading-logo-atol svg {
	transition: all ;
}
.loading-logo-atol path:nth-child(5){
	animation: fill 0.4s ease forwards 0s;
}
.loading-logo-atol path:nth-child(2){
	animation: fill 0.4s ease forwards 0.4s;
}
.loading-logo-atol path:nth-child(3){
	animation: fill 0.4s ease forwards 0.8s;
}
.loading-logo-atol path:nth-child(1){
	animation: fill 0.4s ease forwards 1.2s;
}
.loading-logo-atol path:nth-child(4){
	animation: fill 0.4s ease forwards 1.8s;
}
.loading-logo-atol path:nth-child(6){
	animation: fill 0.4s ease forwards 2.2s;
}

@keyframes fill {
	from {
		fill: white;
	}
	to {
		fill: #e50505;
	}
}

//form-item
.styledFormItem {
	margin-bottom: 8px;
	.ant-form-item-explain{
		min-height: 0;
	}
}
.styledFormItem > div  {
	padding: 2px !important
}
	

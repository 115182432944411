@import '../../variables.scss';

.btnText{
	display: block !important;
	@media (max-width: $md) {
		display: none !important;
	}
}
.btnIcon{
	font-size: 24px;
	line-height: 64px;
	margin-bottom: 3px;
	display: none !important;
	@media (max-width: $md) {
		display: block !important;
	}
}
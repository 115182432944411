:root {
	--menu-first-color:#002140;
	--menu-second-color: #000C17;
	--menu-selected-color: #60A5FA;
}

.atol {
	--menu-first-color: #2c3f4a;
	--menu-second-color: #131d2477;
	--menu-selected-color: #00000083;
}

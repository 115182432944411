$background: -webkit-radial-gradient(0 100%,ellipse cover,rgba(104,128,138,.4) 10%,rgba(138,114,76,0) 40%),linear-gradient(180deg,rgba(57,173,219,.25) 0,rgba(42,60,87,.4)),linear-gradient(135deg,#000000,#092756);
$white: #FFFFFF;
$shade: rgba(25, 41, 48, 0.1);
$duration: 3s;

.AN {
  background: $background;
  position: absolute;
  width: 100%;
  height: 100vh;
  user-select: none;
  animation-name: AN_showApp;
  animation-duration: $duration;
  animation-timing-function: ease-in-out;
  // animation-iteration-count: infinite
  z-index: 9999999; }


.AN_title {
  position: absolute;
  width: 100%;
  height: 100%;
  display: table; }

.AN_h1 {
  color: $white;
  text-transform: uppercase;
  margin: 0;
  width: 100%;
  text-align: center;
  position: relative;
  z-index: 20;
  display: table-cell;
  vertical-align: middle;
  font-size: 5vw;
  font-weight: 900;
  letter-spacing: 0.2em;
  text-shadow: 0 2px 5px $shade, 0 4px 5px $shade, 0 6px 5px $shade, 0 8px 5px $shade, 0 10px 5px $shade, 0 12px 5px $shade, 0 14px 5px $shade, 0 16px 5px $shade, 0 18px 5px $shade, 0 20px 5px $shade;
  font-family:  initial !important; }
.AN-logo-wrap {
	position: relative;
	display: grid;
	grid-template-columns: 2fr 1fr 1fr;
	text-align: left; }

.AN_layers {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden; }

.AN_layer {
  z-index: 9999999;
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  animation-name: AN_zoom;
  animation-duration: $duration;
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  &.layer-2 {
    animation-delay: 0.1s; }


  &.layer-3 {
    animation-delay: 0.3s; }


  &.layer-4 {
    animation-delay: 0.4s; }


  &.layer-5 {
    animation-delay: 0.5s; } }



.AN_star {
  width: 11px;
  height: 11px;
  display: inline-block;
  position: absolute;
  fill: #ffffff;

  &:nth-child(3n+3) {
    fill: #FEC777; }

  &:nth-child(5n+5) {
    fill: #498FB3; } }



@keyframes AN_zoom {
  0% {
    transform: scale(0.1);
    opacity: 0.4; }

  90% {
    opacity: 1; }

  100% {
    transform: scale(2);
    opacity: 0; } }


@keyframes AN_showApp {
  0% {
    opacity: 1; }

  10% {
    opacity: 1; }

  // 100%
 }  //   opacity: 0.4

.menu_item_nav_flex .ant-menu-submenu-title {
	display: flex; }

// If you want to override variables do it here
@import "variables";

// Import styles
@import "../../../node_modules/@coreui/coreui/scss/coreui";

// Temp fix for reactstrap
@import '../../../node_modules/@coreui/coreui/scss/dropdown-menu-right';

// If you want to add something do it here
// @import "../../scss/custom";

// ie fixes
@import "ie-fix";

* {
	font-family: Roboto;
	outline: none !important;
}

a{
	text-decoration: none; 
}
a:hover{
	text-decoration: none; 
}

.card{
	margin-bottom: unset;
}

svg {
 vertical-align: unset;
}


// sass
@import "index.sass";
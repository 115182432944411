@import '~antd/dist/antd.less';
@import '~antd/lib/style/themes/default.less';
@import "./variables.css";

//дефолтные стили анта
//https://github.com/NG-ZORRO/ng-zorro-antd/blob/master/components/style/themes/default.less

@border-radius-base: 6px;
// @body-background: #E0E7FF;

@primary-color: #60A5FA;
@bg-color: #fff;
@error-color: #DC2626;
@text-color: #44403C;
@heading-color: #44403C;

// @border-color-split: #d9d9d9;
// @menu-dark-color: @primary-color;
// @menu-dark-highlight-color: #fff;

@menu-dark-bg: var(--menu-first-color);

@ant-layout-sider:  var(--menu-first-color);
@layout-sider-background:  var(--menu-first-color);
@layout-trigger-background:  var(--menu-first-color);

@menu-dark-inline-submenu-bg:  var(--menu-second-color);
@menu-dark-item-active-bg: var(--menu-selected-color);

// @menu-item-padding: 1px;
// @menu-item-color: #fff;
// @menu-highlight-color: #fff;
// @menu-item-active-bg: inherit;

// @layout-body-background: @body-background;
@layout-header-background: @bg-color;
// @layout-header-height: 67px;
@layout-header-padding: 0 12px;

// @descriptions-bg: @body-background;

// @modal-content-bg: @body-background;

// @page-header-padding: 0px;

// @table-header-bg: @body-background;
// @table-footer-bg: @body-background;




$error: #f86c6b;

// для легаси кода
body.header-fixed {
  overflow-y: auto !important; }
body {
  overflow-y: auto !important; }

// ----
* {
  outline: none !important;
  scrollbar-width: thin;
  scrollbar-color: #dddddd #F5F5F5;

  &::-webkit-scrollbar {
    width: 8px;
    background-color: #F5F5F5; }
  &::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(191, 191, 191, 0.3);
    background-color: #dddddd; } }
.defForm_block {
  // margin-bottom: 0
  // justify-content: center !important
  .defForm_fieldRow {
    margin-bottom: 10px; } }

// login
.def_guestPage {
  // background: url(../../../core/assets/bg1.jpeg)no-repeat center
  background: #f5f5f5;
  background: -moz-radial-gradient(0% 100%, ellipse cover, rgba(104,128,138,.4) 10%,rgba(138,114,76,0) 40%),-moz-linear-gradient(top, rgba(57,173,219,.25) 0%, rgba(42,60,87,.4) 100%), -moz-linear-gradient(-45deg, #670d10 0%, #092756 100%);
  background: -o-radial-gradient(0% 100%, ellipse cover, rgba(104,128,138,.4) 10%,rgba(138,114,76,0) 40%), -o-linear-gradient(top, rgba(57,173,219,.25) 0%,rgba(42,60,87,.4) 100%), -o-linear-gradient(-45deg, #670d10 0%,#092756 100%);
  background: -ms-radial-gradient(0% 100%, ellipse cover, rgba(104,128,138,.4) 10%,rgba(138,114,76,0) 40%), -ms-linear-gradient(top, rgba(57,173,219,.25) 0%,rgba(42,60,87,.4) 100%), -ms-linear-gradient(-45deg, #670d10 0%,#092756 100%);
  background: -webkit-radial-gradient(0% 100%, ellipse cover, rgba(104,128,138,.4) 10%,rgba(138,114,76,0) 40%), linear-gradient(to bottom, rgba(57,173,219,.25) 0%,rgba(42,60,87,.4) 100%), linear-gradient(135deg, #670d10 0%,#092756 100%);
  width: 100%;
  //height: 100%
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center; }

.LgNewApp {
  width: 100% !important;
  max-width: 90vw !important;
  &_card {
    width: 100% !important;
    background: #ffffff;
    border-radius: 6px;
    padding: 50px 30px 1px !important; }
  &_h1 {
    display: flex;
    justify-content: flex-end;
    &_text {
      text-align: right;
      color: #ffffff;
      font-size: 38px;
      position: relative;
      &::before {
        left: -25px;
        top: 17px;
        content: '';
        display: block;
        position: absolute;
        background: #ffffff;
        border-radius: 100%;
        height: 15px;
        width: 15px; } } } }

.tbl_center {
  display: flex;
  align-items: center;
  justify-content: center; }

.app {
  background: #fff; }

.defBtn_center {
  display: flex !important;
  align-items: center; }

.defBtn_center2 {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important; }

.ant-message-custom-content {
  display: flex !important;
  align-items: center !important; }

.tb {
  &_filters_top {
    display: flex;
    margin-bottom: 10px; }
  &_filters_bot {
    padding-top: 10px;
    border-radius: 5px;
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.16);
    .defForm_block {
      margin-bottom: 0;
      justify-content: center !important; }
    .defForm_fieldRow {
      margin-bottom: 10px;
      flex-basis: 65% !important;
      max-width: 65% !important;
      .defForm_fieldRow_field:first-child {
        flex-basis: 40% !important; }
      .defForm_fieldRow_field:last-child {
        flex-basis: 60% !important;
        max-width: initial !important; } } }
  &_filters {
    margin-right: 10px;
    &_btnS {
      display: flex !important;
      justify-content: center !important;
      align-items: center !important; }
    &_min {
      align-items: center !important;
      flex-basis: initial !important;
      margin-right: 10px;
      .defForm_fieldRow_field {
        max-width: initial !important;
        flex-basis: initial !important; } } }
  &_top {
    margin: 0 0 10px 0;
    display: flex;
    align-items: flex-start;
    &_link {
      display: block; }
    &_refresh {
      min-width: 30px;
      max-width: 30px; }
    &_btn:not(:last-child) {
      margin-right: 10px; }
    &_reload {
      display: flex !important;
      align-items: center;
      justify-content: center; } } }

.header_userInfo {
  margin-left: 5px !important;
  margin-right: 46px;
  .dropdown-menu {
    min-width: 150px !important;
    max-width: 150px !important;
    right: calc(50% - 75px) !important;
    top: calc(100% + 6px) !important;
    .collapse.show {
      border-bottom: 1px solid #e4e7ea; } } }

.Select-control {
  box-shadow: none !important; }

.reactSelectTop {
  .Select-menu-outer {
    top: auto !important;
    bottom: 100% !important;
    margin-top: 0px !important;
    margin-bottom: -1px !important;
    border-bottom-right-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border-top-left-radius: 4px !important;
    border-top-right-radius: 4px !important;
    box-shadow: none !important; }
  .Select-control {
    border-bottom-right-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important; } }

.def-reactPhone {
  &.is-invalid {
    .ant-input {
      border-color: $error !important; } } }

.mappPage {
  &_block {
    margin-bottom: 45px; }
  h3 {
    font-size: 23px;
    color: #454647;
    margin: 10px 0 30px; } }

// -- menu
.menu_wrap {
  z-index: 0;
  position: relative;
  width: 250px;
  transition: width 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  background:  #001529 !important;
  .ps__rail-y {
    display: none !important; }
  &.hiden {
    width: 80px; } }
.menu_scroll {
  user-select: none;
  max-width: 250px !important;
  width: 250px !important;
  height: 100vh !important;
  position: fixed !important;
  left: 0; }
.menu {
  min-height: 100vh !important;
  height: 100% !important;
  background:  #001529 !important;
  &_head {
    // background: url("../assets/Logo.svg")no-repeat center
    // height: 72px
    display: flex;
    position: relative;
    max-width: 100%;
    object-fit: cover;
    align-items: center;
    justify-content: center;
    height: 82px;
    text-shadow: 0 0 4px #737373;
    box-shadow: inset -1px -1px 3px #474747;
    padding: 8px 0;
    margin-bottom: 0px;
    color: #ffffff;
    text-align: center;
    font-weight: 600;
    img {
      object-fit: contain;
      max-width: 100%;
      max-height: 100%; }
    span {
      font-weight: 400;
      opacity: 0.6;
      font-size: 16px; } } }

// --- form
.defTabs {
  &_panel {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &_left {
      & > button:not(:last-child) {
        margin-right: 10px; } }
    & > div {
      display: flex;
      align-items: center; }
    font-weight: 500 !important; }
  .defTab {
    display: flex;
    align-items: center;
    &_checked {
      margin-left: 5px;
      margin-right: 0px !important;
      color: #1d9948 !important; } } }
.defForm {
  &-header {
    padding: 15px 1.25rem 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-info {
      display: flex;
      align-items: center;
      .maSla {
        margin-right: 20px;
        font-weight: 500;
        color: #5a5a5a; } }
    &-top {
      display: flex;
      align-items: center; }
    i {
      cursor: pointer; } }
  &_title {
    font-size: 23px;
    color: #454647;
    margin-bottom: 25px;
    margin-top: 10px; }
  &_btn_group_item {
    i {
      display: block !important; } }
  &_btn {
    display: flex !important;
    align-items: center; }
  &_errorBorder {
    border-color: $error !important;
    border: 1px solid;
    border-radius: 6px;
    input, .ant-select-selection {
      border-color: $error !important; } }
  &_errorTextAreaBorder {
    border-color: $error !important; }
  &_row {
    align-items: baseline;
    display: flex;
    flex-wrap: wrap;
    & > .defForm_block:nth-child(2n) {
      padding-left: 15px; }
    & > .defForm_block:nth-child(2n+1) {
      padding-right: 15px; } }
  &_blockFullChkbx {
    max-width: 100% !important;
    flex-basis: 100% !important;
    .defForm_fieldRow {
      flex-direction: row-reverse;
      border-bottom: 1px solid #d9d9d9;
      padding-bottom: 5px; }
    .defForm_fieldRow_field:first-child {
      max-width: 100% !important;
      flex-basis: 100% !important; }
    .defForm_fieldRow_field:last-child {
      width: initial !important;
      min-width: 100px !important;
      max-width: initial !important;
      flex-basis: initial !important; } }
  &_block {
    width: 100%;
    flex-wrap: wrap;
    display: flex;
    flex-grow: 0;
    max-width: 50%;
    flex-basis: 50%;
    margin-bottom: 30px; }
  &_fieldRow {
    flex-direction: column;
    display: flex;
    flex-grow: 0;
    max-width: 100%;
    flex-basis: 100%;
    align-items: flex-start;
    // margin-bottom: 18px
    // min-height: 32px
    & > div:first-child {
      padding-bottom: 3px; }
    &_field {
      flex-grow: 0;
      width: 100%;
      max-width: 100%;
      flex-basis: 100%;
      position: relative;
      &_error {
        position: absolute;
        right: 0;
        top: 100%;
        font-size: 11px;
        color: red; }
      &_select {
        width: 100%; }
      &_searchBtn button {
        display: flex;
        align-items: center; } } }
  &_requiredField {
    color: #f63c3a; } }

// additionally
.defForm_row_fieldCheckbox {
  padding: 0 0 5px !important;
  border-bottom: 1px solid #e2e2e2;
  width: 100%;
  & > div:first-child {
    flex-basis: 80%;
    max-width: 100%; }
  & > div:last-child {
    flex-basis: 20%;
    display: flex;
    justify-content: center; } }

.hiddenTooltip {
  display: none !important; }

.card {
  border: none !important;
  box-shadow: 0px 0px 8px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12); }
.card-header {
  border-bottom: 2px solid #1890ff;
  color: #333;
  background-color: #fff;
  font-size: 15px;
  text-shadow: 0 0 1px #adadad; }

.btnGreen {
  background: #52c41a !important;
  color: #fff !important; }

.spinFull {
  width: 100%;
  height: 100%;
  .ant-spin.ant-spin-spinning {
    max-height: inherit !important; } }

#tablePage {
  .ReactTable .rt-tbody {
    overflow-x: hidden !important; } }

.statusLeft {
  text-align: left !important;
  padding-left: 10px; }

.bankTable {
  width: 100% !important;
  margin: 10px 0 25px 0; }

.defModal {
  padding: 5vh !important;
  min-width: 1000px !important;
  width: initial !important;
  max-width: 90%  !important; }

.marginNull {
  margin: 0 !important; }

.tab4 {
  margin-bottom: 25px;
  width: 100%;
  &_modal {
    padding: 5vh !important;
    width: 90%  !important;
    .ant-modal-footer > div > button:first-child {
      display: none !important; }
    &_left {
      flex-basis: 60%; } } }



.tab4List {
  width: 70%;
  margin: 30px auto 0;
  &_btn {
    &:hover {
      color: #40a9ff; } }
  &_item {
    &:hover {
      border-color: #40a9ff;
      background: #ededed8f;
      cursor: pointer;
      & h4 {
        color: #40a9ff; } } } }

.qrFields {
  margin-right: 10px;
  .tab4_modal_list_item_bottom > span {
    max-width: initial !important;
    min-width: initial !important; } }

.qr_modal_result {
  padding: 20px !important; }

.loaderOld {
  z-index: 100 !important; }

.infoDrag {
  right: 0;
  max-width: 420px;
  position: absolute;
  .close-button {
    padding: 0 !important; }
  .service-desk__text {
    white-space: initial; }
  .card {
    padding: 10px; } }

.noData {
  margin: 10px 0;
  text-align: center;
  color: rgba(0, 0, 0, 0.65);
  font-size: 20px; }

.nameDef {
  margin: 10px 0;
  text-align: center;
  color: rgba(0, 0, 0, 0.65);
  font-size: 20px;
  font-style: italic;
  border-bottom: 1px solid #eaeaea; }

.textareaDef {
  resize: none; }

div.stableTab4_modal .ant-modal-footer > div > button:first-child {
  display: initial !important; }

.defaultModal {
  margin-bottom: 0px !important; }

.uploadError .ant-upload-select {
  border-color: red !important; }

.anResultTms {
  padding: 0 !important;
  align-self: flex-end !important; }


.defTransfer {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  display: flex;
  .ant-transfer-operation {
    & > button {
      display: flex;
      justify-content: center;
      align-items: center; } }
  .ant-btn, .ant-transfer-list-search-action {
    justify-content: center;
    align-items: center;
    display: flex; }
  & > .ant-transfer-list {
    flex-basis: 48%;
    height: 300px; } }

.w100 {
  width: 100%; }

.successBorder {
  .ant-input {
    border-color: #00bf8f !important; } }

// block popup filters
.popFilters {
  min-width: 350px; }

.Regnew {
  &Btns {
    flex-grow: 2;
    display: flex;
    justify-content: space-between;
    & > div {
      display: flex;
      button {
        margin-right: 10px; } } } }

.maDashboard {
  border-bottom: 1px solid rgba(0,0,0,0.05);
  margin-bottom: 10px; }

.table {
  &-infoCellWrap {
    display: flex;
    justify-content: center;
    align-items: center; }
  &-infoCell {
    display: flex;
    justify-content: center;
    align-items: center;
    & > i:not(:last-of-type) {
      margin-right: 6px; }
    &_deact {
      color: #80808059 !important; } } }

.noAccessToPage {
  text-align: center;
  font-weight: 500;
  font-size: 16px; }

// --- [hints] for form reg ---
@mixin frHints-inp {
  box-shadow: 0 0 11px 0px #20a8d8 !important;
  border-radius: 4px; }

@keyframes animateHint {
  0% {
    box-shadow: 0px 0 16px 3px #20a8d8; }
  50% {
    box-shadow: 0px 0 19px 3px #20a8d8; } }

.frHints {
  &_st {
    display: flex;
    align-items: center;
    &_hintCont {
      &_light {
        font-weight: 500;
        margin-bottom: 10px;
        .frHints_tab {
          display: inline-block !important; } } }
    &_hintCont {
      width: 600px;
      max-width: 600px; }
    &_back {
      margin-right: 5px;
      height: 22px !important;
      width: 22px !important; }
    &_hint {
      height: 22px;
      padding: 0 12px;
      font-size: 13px;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      color: rgba(0,0,0,.65);
      background-color: #fff;
      display: flex;
      align-items: center;
      border: 1px solid #d9d9d9;
      cursor: pointer;
      transition: all .3s cubic-bezier(.645,.045,.355,1);
      &:hover {
        color: #40a9ff;
        border-color: #40a9ff; }
      i {
        margin-left: 5px; } }
    h5 {
      display: flex;
      height: 22px;
      margin: 0;
      & > span {
        cursor: pointer;
        margin: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; } }
    &_validation {
      height: 22px;
      padding: 0 12px;
      font-size: 13px;
      color: rgba(0,0,0,.65);
      background-color: #fff;
      display: flex;
      align-items: center;
      border: 1px solid #d9d9d9;
      cursor: pointer;
      transition: all .3s cubic-bezier(.645,.045,.355,1);
      &:hover {
        color: #b5bd73;
        border-color: #b5bd73; }
      i {
        margin-left: 5px; } } }
  &_tab {
    position: relative;
    z-index: 2;
    &::after {
      // animation: animateHint 2s ease infinite
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      border-radius: 40px;
      width: 100%;
      height: 0;
      box-shadow: 0px 0 16px 3px #20a8d8; } }
  &_btn {
    box-shadow: 0 0 11px 0px #20a8d8 !important; }
  &_inp {
    @include frHints-inp; }
  &_progress {
    max-width: 400px;
    width: 400px;
    .ant-steps-item-content {
      width: unset !important; } } }

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  align-items: center; }

.ant-menu-item.ant-menu-item-only-child {
  .ant-menu-title-content {
    white-space: normal;
    line-height: 18px; } }

.ant-menu-sub.ant-menu-inline > .ant-menu-item {
  min-height: 40px;
  height: fit-content; }

.ant-menu-title-content {
  white-space: normal;
  line-height: 18px; }

.ant-menu-submenu-title {
  min-height: 40px;
  height: fit-content; }
.ant-pagination.ant-table-pagination.ant-table-pagination-right {
    margin-right: 16px; }
.date-picker_reverse-direction {
	.ant-picker-panel-container {
		display: flex;
		flex-direction: column-reverse; } }

.date-range-picker_mobile_fix {
	@media (max-width: 645px) {
		.ant-picker-panel:last-child .ant-picker-date-panel {
			display: none !important; }

		.ant-picker-panel:first-child {}
		.ant-picker-date-panel {}
		.ant-picker-header-next-btn,
		.ant-picker-panel:first-child {}
		.ant-picker-date-panel {}
		.ant-picker-header-super-next-btn {
			visibility: initial !important; }
		.ant-picker-panel.ant-picker-range-wrapper {
			min-width: 288px !important; }
		.ant-picker-presets {
			display: none !important; } } }


@import '../../variables.scss';

.header{
	@media (max-width: $md) {
		position: fixed;
		top: 0px;
		left: 0;
		right: 0;
		z-index: 10;
	}
   
}

.headerContent{
	height: 100%;
	
	display: grid;
	grid-template-areas: '. loginAs serviceDesk userMenu';
	grid-template-columns: 1fr 230px  250px auto;
	@media (max-width: $md) {
		grid-template-areas: '. serviceDesk userMenu';
		grid-template-columns: 1fr  80px auto;
	}
	.loginAs{
		grid-area: loginAs;
		display: block;
		@media (max-width: $md) {
			display: none;
		}
	}
	.serviceDesk{
		grid-area: serviceDesk;
	}
	.userMenu{
		grid-area: userMenu;
	}
}
.headerServiceDeskHidden{
	grid-template-areas: '. loginAs userMenu';
	grid-template-columns: 1fr  250px auto;
	@media (max-width: $md) {
		grid-template-areas: '. userMenu';
		grid-template-columns: 1fr  auto;
	}
}
.menuToggle{
	padding: 0 24px;
	font-size: 24px;
	line-height: 64px;
	cursor: pointer;
	transition: all 0.3s;
	color: #1890ff;

	z-index: 1;
	display: none;
	position: fixed;
	top: 3px;
	left: -4px;

	@media (max-width: $md) {
		display: block;
	}
}
  
.menuToggle:hover {
	opacity: 0.7;
}
.siderMenuToggle {
	cursor: pointer;

	z-index: 1;
	position: absolute;
	height: 80px;
	width: 40px;
	right: 8px;
	top: 13px;
	opacity: 1 !important;
	visibility: visible;
	transition: all 300ms;

	font-size: 30px !important;
	line-height: 64px;
	:hover{
		opacity: 0.8;
	}
}

.menuToggleHidden {
	opacity: 0;
	visibility: hidden;
	pointer-events: none;
}
.sider{
	overflow: auto;
	height: 100vh;
	position: sticky;
	top: 0;
	left: 0;
	@media (max-width: $md) {
		position: fixed;
		z-index: 12;
		:global{
			.ant-menu-title-content{
				font-size: 16px;
			}
		}
		
	}
	.menuHead{
		@media (max-width: $md) {
			img{
				max-width: 210px;
				//margin-left: 10px;
			}
		}
	}
}
.loginBtn{
	@media (max-width: $md) {
		font-size: 16px;
	}
}

.mainLayout{
	@media (max-width: $md) {
		margin-top: 64px;
	}
}